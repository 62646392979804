@import "@styles/variables.scss";
@import "@styles/animations.scss";

.characterSection {
    background: $background;
    min-height: $allHeight;
    padding: 2rem 0;
    overflow: hidden;
}

.swiper-box {
    overflow: visible !important;
}

.custom-pagination {
    bottom: 10px;
    left: 0;
    width: 100%;
    padding-top: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}
