$background: linear-gradient(to top, #262a34, #222028, #1b181c, #110e10, #000000 80%);
$primary-text: #fff;
$allHeight: 100vh;
// @media querys
$xs: 0;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1536px;

@mixin xs {
    @media only screen and (min-width: $xs) {
        @content;
    }
}

@mixin sm {
    @media only screen and (min-width: $sm) {
        @content;
    }
}

@mixin md {
    @media only screen and (min-width: $md) {
        @content;
    }
}

@mixin lg {
    @media only screen and (min-width: $lg) {
        @content;
    }
}

@mixin xl {
    @media only screen and (min-width: $xl) {
        @content;
    }
}
