.swiper-pagination {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
}

.swiper-pagination-bullet {
    border-radius: 0px;
    width: 60px;
    height: 5px;
    background: #555;
    margin-right: 1rem;
}
.swiper-pagination-bullet-active {
    background: #fff;
    box-shadow: 0px 0px 10px #fff;
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animate__fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
