@import "@styles/variables.scss";
@import "@styles/animations.scss";

.section {
    background: url("../../../assets/utils/background.svg") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    min-height: $allHeight;
    .big-box-perks {
        -webkit-animation: rotate-right 3s linear infinite;
        animation: rotate-right 3s linear infinite;
    }
    .big-perks-reverse {
        -webkit-animation: rotate-left 3s linear infinite;
        animation: rotate-left 3s linear infinite;
    }
}