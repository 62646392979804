@import './animations.scss';

.loading {
    display: flex;
    width: 45px;
    height: 45px;
    &::after {
        content: " ";
        display: flex;
        width: 25px;
        height: 25px;
        margin: 8px;
        border-radius: 50%;
        border: 2px solid #fff;
        border-color: #fff transparent #fff transparent;
        animation: loading 1.2s linear infinite;
    }
}
