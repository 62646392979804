@import "@styles/variables.scss";

.box-content {
    // background: $background;
    // min-height: $allHeight;
    // background-attachment: fixed;

    .box-character {
        position: relative;
        &::before {
            content: "";
            width: 100%;
            height: 100%;
            max-height: 80%;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0.1;
            background-size: 100% 100% !important;
            z-index: 1;
        }
        &.survivor {
            &::before {
                background: url("../../../assets/utils/survivor_logo.svg") no-repeat;
            }
        }
        &.killer {
            &::before {
                background: url("../../../assets/utils/killer_logo.svg") no-repeat;
            }
        }
        img {
            position: relative;
            z-index: 2;
        }
    }
    img {
        filter: drop-shadow(5px 5px 5px #000);
    }
}
