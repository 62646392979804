@import "@styles/variables.scss";

.box-perk-detail {
    background: #010b0f;
    color: #fff;
    min-height: $allHeight;
    .box-img-perk {
        height: 150px;
        background: $background;
        justify-content: center;
        display: flex;
        align-items: center;
        img {
            filter: drop-shadow(5px 5px 5px #000);
        }
    }
    .box-perk-text {

    }
}
