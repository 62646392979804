@import "@styles/variables.scss";
@import "@styles/animations.scss";

.section1 {
    background: url("../../assets/utils/background.svg") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    min-height: $allHeight;
    .big-box-perks {
        -webkit-animation: rotate-right 3s linear infinite;
        animation: rotate-right 3s linear infinite;
    }
    .big-perks-reverse {
        -webkit-animation: rotate-left 3s linear infinite;
        animation: rotate-left 3s linear infinite;
    }
}

.section2 {
    background: $background;
    min-height: $allHeight;
    padding: 2rem 0;
    overflow: hidden;
}

.swiper-box {
    overflow: visible !important;
}
