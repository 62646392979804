@import "@styles/variables.scss";
@import "@styles/animations.scss";

.box-build {
    .card {
        background: rgba(77, 76, 76, 0.07);
        color: #fff;
        border: 1px solid #222;
        border-radius: 1rem;
        hr {
            border-color: #444;
        }
    }

    table {
        td {
            color: #fff;
            border-bottom-color: transparent;
        }
    }

    .characterSection {
        background: $background;
        min-height: $allHeight;
        padding: 2rem 0;
        overflow: hidden;
    }

    .swiper-box {
        overflow: visible !important;
    }

    .custom-pagination {
        bottom: 10px;
        left: 0;
        width: 100%;
        padding-top: 1rem;
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
    }
    .avatar {
        img {
            height: 50px;
            width: 50px;
        }
    }
}
