@import "@styles/variables.scss";

.box-shadow {
    box-shadow: 0px 2px 4px -1px #00000033, 0px 4px 5px 0px #00000024, 0px 1px 10px 0px #0000001f !important;
}

.not-shadow {
    box-shadow: none !important;
}

.avatar {
    background: transparent !important;
}

.box-avatar {
    position: relative;
    background: url("../../assets/utils/iconProfile.png");
    background-size: cover;
}
