@import "@styles/variables.scss";
@import "@styles/animations.scss";

hr {
    border-color: #444 !important;
}

.box-build {
    table {
        td {
            color: #fff !important;
            border-bottom-color: transparent !important;
        }
    }
}