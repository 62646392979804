.box-border-perk {
    position: relative;
    display: flex;
    border: 3px solid #fff;
    width: 75px;
    height: 75px;
    transform: rotate(45deg);
    margin: 0 1rem 1rem 0.5rem;
    @media screen and (max-width: 600px) {
        width: 60px;
        height: 60px;
      }

    &::before {
        content: "";
        position: absolute;
        height: calc(100% + 10px);
        width: 50%;
        background-color: #000;
        top: -5px;
        left: 25%;
    }
    &::after {
        content: "";
        position: absolute;
        height: 50%;
        width: calc(100% + 10px);
        background-color: #000;
        top: 25%;
        left: -5px;
    }
    img {
        z-index: 2;
        transform: rotate(-45deg);
        margin: auto;
        position: absolute;
        top: -13px;
        left: -13px;
        @media screen and (max-width: 600px) {
            top: -11px;
            left: -11px;
          }
    }
}
