.box-card {
  overflow: visible !important;
  border-radius: 12px !important;
  background: rgba(0, 0, 0, 0.3) !important;
  color: #fff !important;
  box-shadow: 0 0.5rem 1rem #000 !important;
  position: relative;
  & ::before {
    content: "";
    width: 100px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0.01;
  }
  &.survivor {
    & ::before {
      background: url("../../assets/utils/survivor_logo.svg") no-repeat;
    }
  }
  &.killer {
    & ::before {
      background: url("../../assets/utils/killer_logo.svg") no-repeat;
    }
  }
  .box-character {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    margin-top: -80px;
    img {
      filter: drop-shadow(2px 3px 1px #000);
      height: 160px;
      width: auto !important;
      max-width: inherit !important;
    }
  }
  .box-perks {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 80px;
    padding: 1rem 1rem 0;
    img {
      filter: drop-shadow(2px 3px 1px #000);
    }
    .item-perk {
      &:nth-child(2) {
        margin-top: -50px;
        margin-left: -10px;
      }
      &:nth-child(3) {
        transform: translateX(-10px) !important;
      }
      &:last-child {
        transform: translate(-110px, 30px);
      }
    }
  }
  .box-icons {
    border: 1px solid #555;
    padding: 0.5rem;
    margin-top: -18px;
    max-width: 120px;
    margin-left: auto;
    border-radius: 20px;
    background: rgb(34, 34, 34);
    justify-content: space-around;
  }
}
